import React from 'react'

const PoweredByGatsby = () => (
  <div/>
  // <Link
  //   variant='mute'
  //   target='_blank'
  //   title='Gatsby'
  //   href='https://www.gatsbyjs.org'
  //   rel='noopener'
  //   sx={styles.link}
  // >
  //   Powered By
  //   {gatsbySVG && <SVG src={gatsbySVG} />}
  // </Link>
)

export default PoweredByGatsby
